<template>
    <span
        :class="[type, { open: isOpen, nolimit: maxHeight === false, full }]"
        class="dropdown"
        v-bind="$attrs"
        @pointerenter="onHoverStart"
        @pointerleave="onHoverEnd"
    >
        <slot name="element" v-bind="{ toggle, open, close, onKeydown }">
            <Btn
                :active="active !== undefined ? active : isOpen"
                :variant="variant"
                :colour="_colour"
                :hover="hover"
                :active-colour="activeColour"
                :icon="icon"
                :icon-size="iconSize"
                :icon-pos="iconPos"
                :status="status"
                :skeleton="skeleton"
                :disabled="disabled || readonly"
                :hide-icon="hideIcon"
                :slim="slim"
                :force-label="forceLabel"
                :class="{ 'visually-enabled': readonly }"
                clamp
                aria-haspopup="true"
                :aria-expanded="isOpen"
                @click="toggle"
                @keydown="onKeydown"
                @pointerover="$emit('pointerover', $event)"
            >
                <Txt v-if="$slots.default && (size || heading)" :size="size" :heading="heading">
                    <slot />
                </Txt>
                <slot v-else />
                <Icon
                    v-if="chevron && chevronIcon"
                    :name="chevronIcon"
                    :size="iconSize"
                    class="chevron"
                />
            </Btn>
        </slot>
        <Expand v-if="type === 'inline'">
            <div v-if="isOpen || alwaysOpen" class="dropdown-inline" @click.stop>
                <slot name="menu" />
            </div>
        </Expand>
        <Popup
            v-else
            ref="popup"
            v-model:open="isOpen"
            v-model:hover="isHovering"
            type="dropdown"
            :position="position"
            :align="align"
            :boundary="boundary"
            :teleport="teleport"
            :focus-trap="focusTrap"
            :dark="dark"
            :full="full"
            :role="_role"
            :arrow="arrow"
            :typeahead="typeahead"
            :transition="transition"
            :always-open="alwaysOpen"
            :keep-alive="keepAlive"
            :popup-class="popupClass"
            @hover="$emit('hover', $event)"
            @toggle="$emit('toggle', $event)"
            @click="onInnerClick"
        >
            <template #default="popupBind">
                <slot name="menu" v-bind="{ ...popupBind, open, close }" />
            </template>
        </Popup>
    </span>
</template>

<script>
import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Btn from '@/components/utility/button';
import Popup from '@/components/utility/popup';
import Expand from '@/components/animation/expand';

export default {
    name: 'Dropdown',
    components: { Txt, Icon, Expand, Popup, Btn },
    props: {
        variant: { type: String, default: 'ghost', options: 'buttonVariant' },
        colour: { type: String, default: undefined, options: 'buttonColour' },
        hover: { type: String, default: null, options: 'buttonColour' },
        activeColour: { type: String, default: null, options: 'buttonColour' },
        icon: { type: String, default: undefined, options: 'icon' },
        iconSize: { type: String, default: undefined },
        iconPos: { type: String, default: 'left' },
        status: { type: String, default: null },
        size: { type: String, default: null, options: 'textSize' },
        heading: { type: Boolean, default: false },
        hideIcon: { type: Boolean, default: false },
        slim: { type: Boolean, default: false },
        fullscreen: { type: Boolean, default: false },
        role: { type: String, default: null },

        type: { type: String, default: 'dropdown', options: 'dropdownType' },
        position: { type: String, default: 'bottom' },
        align: { type: String, default: 'left' },
        boundary: { type: HTMLElement, default: undefined },
        maxHeight: { type: [Number, Boolean], default: true },
        transition: { type: String, default: undefined },
        popupClass: { type: String, default: undefined },

        arrow: { type: Boolean, default: false },
        chevron: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        skeleton: { type: Boolean, default: false },
        translucent: { type: Boolean, default: false },
        dark: { type: Boolean, default: false },
        full: { type: Boolean, default: false },
        teleport: { type: [Boolean, String], default: false },
        focusTrap: { type: Boolean, default: true },
        multiselect: { type: Boolean, default: false },
        typeahead: { type: Boolean, default: true },
        keepAlive: { type: Boolean, default: false },
        alwaysOpen: { type: Boolean, default: false },
        forceLabel: { type: Boolean, default: false },

        hoverable: { type: Boolean, default: false },
        active: { type: [Boolean, String], default: undefined },
    },
    emits: ['click', 'toggle', 'open', 'close', 'hide', 'hover', 'keydown', 'pointerover'],
    slots: ['default', 'element', 'menu'],
    data() {
        return {
            timer: null,
            isOpen: false,
            isHovering: false,
            isRecentlyHovered: false
        };
    },
    computed: {
        chevronIcon() {
            if (!this.chevron) return;
            if (this.type === 'inline') return this.isOpen ? 'chevron-down' : 'chevron-right';
            return 'caret-down';
        },
        _colour() {
            if (this.variant !== 'ghost' || this.colour) return this.colour;
            return 'steel';
        },
        _role() {
            if (this.role) return this.role;
            if (this.type === 'menu') return 'menu';
            if (this.type === 'select') return 'listbox';
            return null;
        }
    },
    watch: {
        isOpen(to) {
            this.$emit(to ? 'open' : 'close', to);
            document[to ? 'addEventListener' : 'removeEventListener']('click', this.onOutsideClick);
        },
        isHovering(to) {
            if (!to) this.onHoverAfter();
        },
        hoverable(to) {
            if (!to) this.isHovering = false;
        },
        disabled(to) {
            if (to) this.close();
        }
    },
    methods: {
        onOutsideClick(event) {
            if (!this.isOpen) return;
            if (this.$el && this.$el.contains(event.target)) return;
            if (this.teleport) {
                const target = this.$refs.popup && this.$refs.popup.$refs && this.$refs.popup.$refs.popup && this.$refs.popup.$refs.popup.$el;
                if (target && !target.contains(event.target)) this.close();
            } else {
                if (this.$el && !this.$el.contains(event.target)) this.close();
            }
        },
        onInnerClick() {
            if (!this.isOpen) return;
            if (this.multiselect) return;
            if (this.type === 'menu' || this.type === 'select') this.close();
        },
        toggle(event, emit = true) {
            if (emit) this.$emit('click', event);
            if (this.disabled || this.skeleton) return;
            this.isOpen = !this.isOpen;
        },
        onHoverStart() {
            if (this.disabled || this.skeleton || this.readonly) return;
            if (this.hoverable && !this.isRecentlyHovered) {
                this.$cancel(this.timer);
                this.isHovering = true;
            }
        },
        onHoverEnd() {
            if (this.hoverable) this.timer = this.$timeout(() => this.isHovering = false, 200);
        },
        onHoverAfter() {
            this.isRecentlyOpened = true;
            this.$timeout(() => this.isRecentlyOpened = false, 500);
        },
        onKeydown(event) {
            this.$emit('keydown', event);
            if (!this.typeahead) return;
            if (this.type !== 'select') return;
            if (this.disabled || this.skeleton) return;

            const { key, altKey, ctrlKey, metaKey } = event;
            const isTyping = key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey;
            if (key === 'ArrowUp' || key === 'ArrowDown' || isTyping) {
                this.open();
                event.preventDefault();
            }
        },
        open() {
            if (this.isOpen) return;
            this.isOpen = true;
        },
        close() {
            if (!(this.isOpen || this.isHovering)) return;
            this.isOpen = false;
            this.onHoverEnd();
        }
    }
};
</script>

<style lang="less" scoped>
.dropdown {
    position: relative;
    display: flex;
    &.inline {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .dropdown-inline {
            display: flex;
            flex-direction: column;
            padding-left: 24px;
        }
    }
}
</style>
<style lang="less">

.dropdown {
    > .cobutton {
        width: 100%;
        justify-content: space-between;
        text-align: left;
        > .txt-component { flex: 1 1 auto; }
        .chevron.icon {
            color: var(--anchor);
            fill: var(--anchor);
            margin: 0;
        }
    }
    &.select:not(.nolimit) {
        .dropdown-popup > .popup-inner {
            max-height: 240px;
        }
        &:not(.full) {
            .dropdown-popup > .popup-inner {
                width: 240px;
            }
        }
    }
    &.menu:not(.nolimit) {
        .dropdown-popup > .popup-inner {
            max-height: 320px;
        }
    }
    &.nolimit {
        .dropdown-popup > .popup-inner {
            max-height: none;
            overflow: visible;
        }
    }
}
.popup.dropdown-popup {
    > .popup-inner {
        flex-direction: column;
        gap: 4px;
        padding: 8px;
        border-radius: 16px;
        box-shadow: var(--shadow-lg);

        min-width: 180px;
        overflow: auto;
        > a, > button, > .cobutton, > .tooltip > .cobutton, .popup-button {
            &:not(.popup-unstyle) {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px;
                text-align: left;
                padding: 12px 16px;
                text-decoration: none;
                flex: 1 0 auto;
                &.checkbox, &[role="menuitemcheckbox"] {
                    justify-content: space-between;
                }
                &:not(.hover\:primary):not(.hover\:danger):not(:disabled):not(.visually-disabled) {
                    color: var(--coal);
                    fill: var(--coal);
                    &:focus { box-shadow: none; }
                    &:hover {
                        background: var(--smoke);
                        color: var(--coal);
                        fill: var(--coal);
                        text-decoration: none;
                        box-shadow: none;
                        > * {
                            color: var(--coal);
                            fill: var(--coal);
                        }
                    }
                }
                &:focus-visible {
                    outline: 2px solid currentColor;
                    outline-offset: 2px;
                }
                &:disabled:not(.visually-enabled), &.disabled:not(.visually-enabled), &.visually-disabled {
                    background: none;
                    color: var(--steel);
                }
            }
        }
    }
    &.dark {
        .chevron {
            color: rgba(255, 255, 255, 0.5);
            fill: rgba(255, 255, 255, 0.5);
        }
        .popup-inner {
            > a, > button, > .cobutton, > .tooltip > .cobutton {
                &:not(.popup-unstyle) {
                    background: none;
                    &:not(.hover\:primary):not(.hover\:danger):not(:disabled) {
                        color: #FFF;
                        fill: #FFF;
                        &:active, &.active {
                            color: #FFF;
                            fill: #FFF;
                            background: rgba(255, 255, 255, 0.2);
                            text-decoration: none;
                            box-shadow: none;
                            > * {
                                color: #FFF;
                                fill: #FFF;
                            }
                            &.checkbox:not(:hover) {
                                background: none;
                            }
                        }
                        &:hover {
                            color: #FFF;
                            fill: #FFF;
                            background: rgba(255, 255, 255, 0.12);
                            text-decoration: none;
                            box-shadow: none;
                            > * {
                                color: #FFF;
                                fill: #FFF;
                            }
                        }
                    }
                    &:disabled > * {
                        color: rgba(255, 255, 255, 0.25);
                        fill: rgba(255, 255, 255, 0.25);
                    }
                }
            }
            > .field {
                .field-container {
                    background: rgba(255, 255, 255, 0.12);
                    border: 1px solid rgba(255, 255, 255, 0.12);
                    color: #FFF;
                    &:hover {
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid rgba(255, 255, 255, 0.5);
                    }
                    &.focus {
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid #FFF;
                    }
                }
                &.disabled {
                    .field-container {
                        color: rgba(255, 255, 255, 0.25);
                        input { color: rgba(255, 255, 255, 0.25); }
                        &:hover {
                            background: rgba(255, 255, 255, 0.12);
                            border: 1px solid rgba(255, 255, 255, 0.12);
                        }
                    }
                }
            }
        }
    }
}
</style>

